<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Master Asuransi</strong>
              </h5>
            </template>
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input-asuransi
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row> -->
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                
                   <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>
                   

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(actions)="item">
                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail Data'"
                      v-b-modal.modal-info-asuransi
                      @click="is_data = item.item"
                      size="sm"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >
                    <b-button
                    
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit-asuransi
                      @click="is_data = item.item"
                      size="sm"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                    
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-master-asuransi
                      @click="is_data = item.item"
                      size="sm"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template> -->
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      :optionsHarga="optionsHarga"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :data_edit="is_data"
      :optionsHarga="optionsHarga"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-info
      :fileName="file_name"
      :data_edit="is_data"
      :optionsHarga="optionsHarga"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";
import ModalInfo from "./modalInfo.vue";

// import { useGetAxios } from "@/composables/useAxios.js";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
    ModalInfo,
  },
  name: "master_asuransi",
  data() {
    return {
      file_name: "ms_asuransi",
      showing: false,
      variant: "success",
      optionsHarga: [],
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "nama_asuransi",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "asuransi_tipe",
          label: "Type",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_telepon_asuransi",
          label: "Phone",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "alamat_asuransi",
        //   label: "Insurance Address",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "cp_nama",
          label: "Representatif",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "cp_alamat",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "cp_hp",
        //   label: "CP Mobile",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "cp_email",
        //   label: "CP Email",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-4 text-center",
        // },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,

      data_res: null,
      data_err: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
    role() {
      return this.$store.state.user_role
    }
  },
  async mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      try {
        this.tableBusy = true;
        let asuransi = await this.$axios.post("/ms_asuransi/list");
        // console.log('asuransi', asuransi)
        let x = asuransi.data;
        if (x.status === 200 && x.message === "sukses") {
          this.items = asuransi.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
            for (let j = 0; j < this.$store.state.data_static.tipe_asuransi.length; j++) {
              const y = this.$store.state.data_static.tipe_asuransi[j];
              if (x.tipe_asuransi == y.value) {
                x.asuransi_tipe = y.text;
              }
            }
          }
          this.totalRows = this.items.length;
        } else {
          throw x;
        }
      } catch (err) {
        if (err.message) {
          this.triggerAlert({
            variant: "danger",
            msg: (err.message).toUpperCase(),
            showing: true,
          });
        } else {
          this.triggerAlert({
            variant: "danger",
            msg: (err).toUpperCase(),
            showing: true,
          });
        }
      } finally {
        this.tableBusy = false;
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
