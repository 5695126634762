<template>
  <div>
    <b-modal
      id="modal-input-asuransi"
      size="lg"
      centered
      title="Tambah Data Master Asuransi"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Asuransi <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_asuransi')"
            type="text"
            v-model="$v.is_data.nama_asuransi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tipe <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="$v.is_data.tipe_asuransi.$model"
            :state="checkIfValid('tipe_asuransi')"
            :options="$store.state.data_static.tipe_asuransi"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Telp <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('no_telepon_asuransi')"
            type="number"
            v-model="$v.is_data.no_telepon_asuransi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Contact Person <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_nama')"
            type="text"
            v-model="$v.is_data.cp_nama.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Alamat <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_alamat')"
            type="text"
            v-model="$v.is_data.cp_alamat.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Telp <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_hp')"
            type="number"
            v-model="$v.is_data.cp_hp.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            CP Email <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('cp_email')"
            type="email"
            v-model="$v.is_data.cp_email.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-asuransi')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["optionsHarga"],
  data() {
    return {
      is_data: {
        nama_asuransi: "",
        tipe_asuransi: "",
        no_telepon_asuransi: "",
        cp_nama: "",
        cp_alamat: "",
        cp_hp: "",
        cp_email: "",
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_asuransi: {
        required,
      },
      tipe_asuransi: {
        required,
      },
      no_telepon_asuransi: {
        required,
      },
      cp_nama: {
        required,
      },
      cp_alamat: {
        required,
      },
      cp_hp: {
        required,
      },
      cp_email: {
        required,
        email,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/ms_asuransi/register", vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER ASURANSI",
              showing: true,
            });
            this.$bvModal.hide("modal-input-asuransi");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_asuransi: "",
        tipe_asuransi: "",
        no_telepon_asuransi: "",
        cp_nama: "",
        cp_alamat: "",
        cp_hp: "",
        cp_email: "",
      };
    },
  },
};
</script>
