<template>
  <div>
    <b-modal
      id="modal-info-asuransi"
      size="lg"
      centered
      :title="'Detail ' + pageName"
      header-bg-variant="warning"
      header-text-variant="light"
      @hidden="tutupModal"
      v-if="is_data"
    >
      <!-- <b-container>
        <b-row>
          <b-col cols="3"> Nama Asuransi </b-col>
          <b-col>
            {{ is_data.nama_asuransi }}
          </b-col>
        </b-row>
      </b-container> -->

      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> Nama Asuransi </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.nama_asuransi }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> Tipe </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.tipe_asuransi }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> Telp </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.no_telepon_asuransi }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> Contact Person </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.cp_nama }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> CP Alamat </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.cp_alamat }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> CP Telp </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.cp_hp }} </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col cols="3"> CP Email </b-col>
            <b-col style="border-left: 1px solid rgba(0, 0, 21, 0.125);"> {{ is_data.cp_email }} </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <!-- <b-form-group label-cols-md="3">
        <template v-slot:label>
          Nama Asuransi <span class="text-danger">*</span>
        </template>
        <b-form-input
          :state="checkIfValid('nama_asuransi')"
          type="text"
          v-model="$v.is_data.nama_asuransi.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Tipe <span class="text-danger">*</span> </template>
        <b-form-select
          v-model="$v.is_data.tipe_asuransi.$model"
          :state="checkIfValid('tipe_asuransi')"
          :options="$store.state.data_static.tipe_asuransi"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Telp <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('no_telepon_asuransi')"
          type="number"
          v-model="$v.is_data.no_telepon_asuransi.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Contact Person <span class="text-danger">*</span>
        </template>
        <b-form-input
          :state="checkIfValid('cp_nama')"
          type="text"
          v-model="$v.is_data.cp_nama.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> CP Alamat <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('cp_alamat')"
          type="text"
          v-model="$v.is_data.cp_alamat.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> CP Telp <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('cp_hp')"
          type="number"
          v-model="$v.is_data.cp_hp.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> CP Email <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('cp_email')"
          type="email"
          v-model="$v.is_data.cp_email.$model"
        ></b-form-input>
      </b-form-group> -->

      <template #modal-footer>
        <b-button variant="primary" @click="$bvModal.hide('modal-info-asuransi')">
          Confirm
        </b-button>
        <!-- <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">
          {{ button }}
        </b-button> -->
      </template>
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "modalInfo",
  props: ["data_edit", "optionsHarga", "fileName"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_asuransi: {
        required,
      },
      tipe_asuransi: {
        required,
      },
      no_telepon_asuransi: {
        required,
      },
      cp_nama: {
        required,
      },
      cp_alamat: {
        required,
      },
      cp_hp: {
        required,
      },
      cp_email: {
        required,
        email,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.$axios
          .post(`/${vm.file_name}/register`, {
            ...vm.is_data,
            id: vm.is_data.ms_asuransi_id,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DAFTAR MASTER ASURANSI",
                showing: true,
              });
              vm.$bvModal.hide("modal-info-asuransi");
              vm.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: res.data.message.toUpperCase(),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_asuransi: "",
        tipe_asuransi: "",
        no_telepon_asuransi: "",
        cp_nama: "",
        cp_alamat: "",
        cp_hp: "",
        cp_email: "",
      };
    },
  },
};
</script>
